<template>
  <div v-if="slider?.length" :class="{ 'pb-1 mb-2': !isNoData || keepSpace }"
       :style="`background: #${data?.background_color};`"
       class="px-3 md:px-0">
    <swiper v-if="slider.length && slider.length > 1" :autoplay="{delay: 3000, disableOnInteraction: true}"
            :loop="true"
            :modules="[SwiperNavigation,SwiperAutoplay,SwiperPagination]"
            :navigation="{ nextEl: nextRef, prevEl: prevRef}"
            :pagination="true"
            :slides-per-view="1"
            class="rounded-md custom-swiper-pagination" lazy-render>
      <div v-if="$device.isDesktop" ref="prevRef" class="swiper-button-prev !w-[44px] flip-icon">
        <niceone-image :src="$publicPath('/images/nav-white-left.svg')" width="44"/>
      </div>
      <div v-if="$device.isDesktop" ref="nextRef" class="swiper-button-next !w-[44px] flip-icon">
        <niceone-image :src="$publicPath('/images/nav-white-right.svg')" width="44"/>
      </div>
      <div class="swiper-pagination"></div>
      <swiper-slide v-for="(image, index) in slider" :key="`banner-${index}`" class="banner">
        <nuxt-link :to="$routerUrl(image.seo_url)">
          <niceone-image :alt="image?.name" :src="image.image_url" @click="onBannerClicked(image,index)"/>
        </nuxt-link>
      </swiper-slide>
    </swiper>
    <div v-else class="banner">
      <nuxt-link :to="slider[0]?.seo_url">
        <niceone-image :alt="slider[0]?.name" :src="slider[0]?.image_url" class="rounded-md"/>
      </nuxt-link>
    </div>
  </div>
</template>

<script setup>
import {TRACKERS} from "@/constants/events";
import {track} from "@/composables/useTrackEvent";

const props = defineProps({
  data: [Object],
  loading: {
    type: Boolean,
    default: false
  },
  keepSpace: {
    type: Boolean,
    default: true
  }
})
const slider = computed(() => {
  return props.data.data.filter((img) => {
    if (!useNuxtApp().$device.isDesktop) return true
    return !img.for_mobile && useNuxtApp().$device.isDesktop;
  })
})

const nextRef = ref(null)
const prevRef = ref(null)

const isNoData = computed(() => props.data.data.find(item => !!item?.nodata && !item?.seo_url))

const onBannerClicked = (banner, index) => {
  track(TRACKERS.BANNER_CLICK, {banner, index})
}
</script>

<style>
.custom-swiper-pagination .swiper-pagination .swiper-pagination-bullet {
  width: 32px;
  height: 3px;
  border-radius: 4px;
  background-color: #fff;
  opacity: unset;
}

.custom-swiper-pagination .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #000;
}

@media screen and (max-width: 600px) {
  .custom-swiper-pagination .swiper-pagination .swiper-pagination-bullet {
    width: 22px;
    height: 2px;
  }
}
</style>